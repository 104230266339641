module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/Users/AOverholt/Disc Ed/Pantheon/genentech/code/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"328d123b235f8d711a029240f4c67921"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"extraDevHostnames":[{"domain":"toc-genentech.pantheonsite.io","region":"US"}],"partnershipId":"2b774ce5-9bb9-4662-8b31-8f54adbb89db","extraStageHostnames":[],"extraProdHostnames":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
